<template>
  <div class="ma-10">
    <v-form>
      <v-row>
        <v-col cols="12">
          <form-step-title title="Parlez nous de vous" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <form-fields-text 
            v-model="nom"
            field-label="Nom"
            placeholder="Nom" 
            class="mt-1"
            required
            @blur="updateMe"
          />
          <form-fields-text 
            v-model="prenom"
            field-label="Prénom" 
            placeholder="Prénom"
            required
            @blur="updateMe"
          />
          <form-fields-text 
            v-model="email"
            field-label="Mail" 
            placeholder="monmail@exemple.com"
            type="email"
            required
            email
            @blur="updateMe"
          />
          <form-fields-text 
            v-model="telephone"
            field-label="Tel" 
            placeholder="01xxxxxxxx"
            @blur="updateMe"
          />
          <form-fields-multi-select 
            v-model="entite_id"
            item-value="id"
            item-text="nom"
            :items="entites"
            field-label="Vous travaillez pour" 
            label="Liste des entités"
            required
            @change="updateMe"
          />
          <form-fields-text 
            v-model="fonction"
            field-label="Fonction" 
            placeholder="Fonction"
            @blur="updateMe"
          />
          <form-fields-textarea
            v-model="commentaire"
            field-label="Commentaires" 
            placeholder="Commentaires"
            type="textarea"
            class="mt-5"
            @blur="updateMe"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import FormStepTitle from '@/components/FormStepTitle.vue'
import FormFieldsText from '@/components/FormFieldsText.vue'
import FormFieldsMultiSelect from '@/components/FormFieldsMultiSelect.vue'
import FormFieldsTextarea from '@/components/FormFieldsTextarea.vue'
import { mapActions, mapState, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields';

export default {
  name: "Person",
  components: { 
    FormStepTitle,
    FormFieldsText,
    FormFieldsMultiSelect,
    FormFieldsTextarea,
  },
  data () {
    return { 
      booleanDataList: [
        {
          label: 'Oui',
          value: true,
        },
        {
          label: 'Non',
          value: false,
        },
      ],
    }
  },
  computed: {
    ...mapFields('authentication', [
      'user.prenom',
      'user.nom',
      'user.email',
      'user.telephone',
      'user.entite_id',
      'user.fonction',
      'user.droit_modif_donnees',
      'user.commentaire',
    ]),
    ...mapState('form', ['entites']),
  },
  created() {
    this.fetchEntites();
    this.fetchMe();
    this.setNextView('/profile/entity');
  },
  methods: {
    ...mapActions('authentication', ['fetchMe', 'updateMe']),
    ...mapActions('form', ['fetchEntites']),
    ...mapMutations('stepper', ['setNextView'])
  },
}
</script>